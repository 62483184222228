<template>
  <v-container fluid>
    <v-row>
      <v-form ref="form" @submit.prevent="validateBeforeSubmit">
        <v-col cols="12">
          <v-text-field v-model="vm.participant.firstname" label="First name" required></v-text-field>
        </v-col>
      </v-form>
    </v-row>
  </v-container>
</template>



<script>
var Models = require("./models.js");
import Vue from "vue";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
export default {
  $_veeValidate: {
    validator: "new"
  },
  name: "about-you-form",
  model: new Models.Case(),
  data: () => ({
    vm: new Models.Case(),
    form: {}
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      this.vm =
        this.$store.getters.currentCase == undefined ||
        this.$store.getters.currentCase.otherDetails == undefined
          ? {}
          : this.$store.getters.currentCase.otherDetails;
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentCase.otherDetails = this.vm;
          this.$store.dispatch("setCurrentCase", this.currentCase);
          this.$emit("next-step");
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

